<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5 px-3">
                    <v-row class="px-3">
                        <v-col cols="0" md="2">
                            <admin-etc-menu></admin-etc-menu>
                        </v-col>
                        <v-col cols="12" md="9">

                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2">
                                    {{comnInfo.comnNm}}
                                </v-col>
                                <v-col cols="12">
                                    <input type="radio" id="0" value="0" v-model="comnOrder"
                                           style="width: 20px; height: 12px;" @change="changRadio($event)">
                                    <label for="0">큰화면(960px 이상)</label>
                                    <input type="radio" id="1" value="1" v-model="comnOrder"
                                           style="width: 20px; height: 12px; margin-left:20px;"
                                           @change="changRadio($event)">
                                    <label for="1">작은화면(960px 이하)</label>
                                </v-col>

                                <v-col cols="12">
                                    <div class="editor-container">
                                        <div id="editor">
                                        </div>
                                    </div>
                                </v-col>
                                <v-col class="mt-6">
                                    <v-btn
                                            color="primary"
                                            class="mx-auto white--text center-block align-center"
                                            small
                                            @click="btnClick"
                                    >
                                        저장
                                    </v-btn>

                                </v-col>

                            </v-row>

                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AdminEtcMenu from '../../components/AdminEtcMenu'

    export default {
        name: 'AdminDefault',
        components: {
            AdminEtcMenu
        },
        data: () => ({
            comnGubun: "",
            comnOrder: "0",
            comnInfo: {
                comnGubun: "",
                comnOrder: "",
                comnNm: "",
                comnCont: "",
            },

            quill: null,

        }),
        created: function () {
            // 맨위로
            if (this.$isMobile()) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            let _this = this;

            const sch = window.location.search;
            const urlParams = new URLSearchParams(sch);

            let comnGubun = urlParams.get('comnGubun');
            let comnOrder = urlParams.get('comnOrder');

            if (comnGubun === null || comnGubun === "") {
                this.comnGubun = 'textBasics';
            } else {
                this.comnGubun = comnGubun;
            }

            if (comnOrder === null || comnOrder === "") {
                this.comnOrder = '0';
            } else {
                this.comnOrder = comnOrder;
            }

            //this.comnGubun = comnGubun;
            this.getData();

        },
        mounted() {
            this.$nextTick(() => {

                let _this = this;

                let options = {
                    modules: {
                        toolbar: {
                            container: [
                                ['bold', 'italic', 'underline', 'strike'],
                                [{'align': []}, {'size': ['small', false, 'large', 'huge']}],
                                [{'color': []}, {'background': []}],
                                ['image'],
                            ],
                        },

                    },
                    placeholder: '내용을 입력해 주세요.',
                    theme: 'snow',

                };

                this.quill = new Quill('#editor', options);
                this.quill.getModule('toolbar').addHandler('image', function () {
                    _this.changeImage();
                });

            })

        },

        methods: {

            changeImage: function (e) {
                let _this = this;

                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.click();

                input.onchange = function () {

                    const fd = new FormData();
                    const file = $(this)[0].files[0];

                    fd.append('file', file);

                    let url = '/file/upload.do';

                    let header = {
                        headers: {'Content-Type': 'multipart/form-data'}
                    };

                    return axios.post(url, fd, header).then(resp => {
                        if (resp.data) {

                            const range = _this.quill.getSelection();
                            //_this.quill.insertEmbed(range.index, 'image', 'http://localhost:8082/file/'+resp.data);
                            _this.quill.insertEmbed(range.index, 'image', resp.data);
                            return resp.data
                        }
                        throw new Error('Server or network error');
                    });
                }
            },
            changRadio(event) {
                this.getData();
            },

            getData() {

                let formdata = {
                    comnGubun: this.comnGubun,
                    comnOrder: this.comnOrder,
                };

                return this.$store.dispatch("admin/getComn", formdata)
                    .then((resp) => {

                        if (resp.message === undefined) {
                            //alert("잘못된 요청입니다.");
                            //window.history.back();
                            //return;
                        } else {

                            this.comnInfo = resp.message;
                        }

                        this.quill.root.innerHTML = this.comnInfo.comnCont;

                    })
                    .catch((err) => {
                    })

            },

            menuClick(route) {
                location.href = route;
                //this.$router.push({path: route});
            },
            btnClick() {

                let cont = this.quill.root.innerHTML;

                let formdata = {
                    comnGubun: this.comnGubun,
                    comnNm: this.comnInfo.comnNm,
                    comnCont: cont,
                    comnOrder: this.comnOrder,
                    lstModPrs: this.$store.state.admin.loginInfo.usrId
                };

                return this.$store.dispatch("admin/updateComn", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            alert("저장완료");
                            setTimeout(() => {
                                location.href = "/admin/default?comnGubun=" + resp.message.comnGubun + "&comnOrder=" + resp.message.comnOrder;
                            }, 300)

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },

        },
        computed: {
            //usrInfo() { return this.$store.state.reservation.usrInfo }
        },

    }
</script>

<style>


    /*.ql-editor {
        height: 550px;
    }*/
</style>
